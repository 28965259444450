<template>
  <div>
    <a-form-model
      ref="currencyForm"
      :model="currencyForm"
      :rules="currencyRules"
    >
      <a-row>
        <a-col :span="9" class="pr-1">
          <a-form-model-item ref="name" label="ISO 4217 код" prop="name">
            <a-input v-model="currencyForm.name" placeholder="RUB, USD, EUR, ..." />
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1 pr-1">
          <a-form-model-item ref="abbr" label="Символ валюты" prop="abbr">
            <a-input v-model="currencyForm.abbr" placeholder="₽, $, €, ..." />
          </a-form-model-item>
        </a-col>
        <a-col :span="9" class="pl-1">
          <a-form-model-item ref="desc" label="Описание" prop="desc">
            <a-input v-model="currencyForm.desc" placeholder="Российский рубль" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="sums" label="Предлагаемые суммы депозита" prop="sums">
            <a-select mode="tags" v-model="currencyForm.d_sums" style="width: 100%" :token-separators="[',']" @change="handleSumsChange">
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row>
      <a-col :span="14">
        <a-button @click="closeModal">
          Отмена
        </a-button>
      </a-col>
      <a-col :span="10" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ currencyInfo ? 'Сохранить' : 'Добавить' }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'currencyModal.vue',
  props: ['currencyInfo'],
  computed: {
    // ---
  },
  data() {
    return {
      submitLoading: false,
      currencyForm: {
        name: this.currencyInfo ? this.currencyInfo.name : '',
        abbr: this.currencyInfo ? this.currencyInfo.abbr : '',
        desc: this.currencyInfo ? this.currencyInfo.desc : '',
        d_sums: this.currencyInfo ? this.currencyInfo.d_sums : '',
        is_active: true,
      },
      currencyRules: {
        name: [{ required: true, message: 'Укажите ISO код', trigger: 'change' }],
        abbr: [{ required: true, message: 'Укажите символ валюты', trigger: 'change' }],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.currencyForm.validate(valid => {
        if (valid) {
          this.currencyInfo ? this.editCurrencySubmit() : this.addCurrencySubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.currencyForm.resetFields()
    },
    handleSumsChange(value) {
      // console.log(`selected ${value}`)
    },
    async addCurrencySubmit() {
      this.submitLoading = true
      return ApiService.createCurrency(this.currencyForm).then((response) => {
        this.$notification.success({
          message: 'Создана новая валюта',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при создании валюты',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async editCurrencySubmit() {
      this.submitLoading = true
      return ApiService.updateCurrency(this.currencyInfo.id, this.currencyForm).then((response) => {
        this.$notification.success({
          message: 'Валюта обновлена',
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data, true)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при обновлении валюты',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
  },
}
</script>
<style lang="scss">
</style>
<style scoped>
</style>
